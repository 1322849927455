import React from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import EntriesCards from "components/shared/EntriesCards";
import Footer from "components/shared/Footer";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";
import { graphql } from "gatsby";
import { intro } from "./index.module.less";

export default (queryResults) => {
  return (
    <>
      <Nav />
      <Meta url="en/news/" />
      <Header>
        <h1>消息</h1>
        <p>随时了解最新趋势和发展</p>
      </Header>
      <Layout>
        <div className={intro}>
          <h2>随时了解情况</h2>
          <p>
            LetsGrow.com 跟踪农业和园艺领域的最新发展。 我们很高兴与您分享这些知识。
          </p>
        </div>
        <EntriesCards posts={queryResults.data.allWpPost.nodes}/>
      </Layout>
      <Footer path="Features" />
    </>
  );
};

export const pageQuery = graphql`
query {
  allWpPost (
    sort: { fields: date, order: DESC }
  ){
    nodes {
      id
      uri
      title
      customFields {
        postsummary
        language
      }
    }
  }
}`;
